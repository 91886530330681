// results
export const FETCH_RESULTS = 'FETCH_RESULTS';
export const FAILED_FETCH_RESULTS = 'FAILED_FETCH_RESULTS';
export const FETCH_INFERNAL_RESULTS = 'FETCH_INFERNAL_RESULTS';
export const FAILED_FETCH_INFERNAL_RESULTS = 'FAILED_FETCH_INFERNAL_RESULTS';

export const TOGGLE_INFERNAL_ALIGNMENTS_COLLAPSED = 'TOGGLE_INFERNAL_ALIGNMENTS_COLLAPSED';
export const TOGGLE_ALIGNMENTS_COLLAPSED = 'TOGGLE_ALIGNMENTS_COLLAPSED';
export const TOGGLE_DETAILS_COLLAPSED = 'TOGGLE_DETAILS_COLLAPSED';
export const TOGGLE_FACET = 'TOGGLE_FACET';
export const RELOAD = 'RELOAD';
export const LOAD_MORE = 'LOAD_MORE';
export const SORT_RESULTS = 'SORT_RESULTS';

export const FETCH_STATUS = 'FETCH_STATUS';
export const SET_STATUS_TIMEOUT = 'SET_STATUS_TIMEOUT';
export const SEARCH_PROGRESS = 'SEARCH_PROGRESS';
export const BATCH_SEARCH = 'BATCH_SEARCH';
export const DOWNLOAD = 'DOWNLOAD';

// submission form
export const SUBMIT_JOB = 'SUBMIT_JOB';
export const SUBMIT_MULTIPLE_JOB = 'SUBMIT_MULTIPLE_JOB';
export const SUBMIT_URS = 'SUBMIT_URS';
export const INVALID_SEQUENCE = 'INVALID_SEQUENCE';
export const UPDATE_JOB_ID = 'UPDATE_JOB_ID';
export const CLEAR_JOB_ID = 'CLEAR_JOB_ID';
export const CLEAR_RESULT = 'CLEAR_RESULT';
export const TEXTAREA_CHANGE = 'TEXTAREA_CHANGE';
export const FILTER_CHANGE = 'FILTER_CHANGE';
export const EXAMPLE_SEQUENCE = 'EXAMPLE_SEQUENCE';
export const CLEAR_SEQUENCE = 'CLEAR_SEQUENCE';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_SEQUENCE = 'UPDATE_SEQUENCE';

// check exact match
export const EXACT_MATCH = 'EXACT_MATCH';

// admin
export const SHOW_ADMIN = 'SHOW_ADMIN';
export const SHOW_LAST_JOB = 'SHOW_LAST_JOB';
export const CONSUMERS = 'CONSUMERS';
export const JOBS_STATUSES = 'JOBS_STATUSES';
export const SET_JOBS_STATUSES_TIMEOUT = 'SET_JOBS_STATUSES_TIMEOUT';

// r2dt
export const SUBMIT_R2DT_JOB = 'SUBMIT_R2DT_JOB';
export const FETCH_R2DT_STATUS = 'FETCH_R2DT_STATUS';
export const FETCH_R2DT_THUMBNAIL = 'FETCH_R2DT_THUMBNAIL';