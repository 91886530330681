const info = [
  { name: '5srrnadb', url: 'http://combio.pl/rrna/' },
  { name: 'crw', url: 'http://www.rna.ccbb.utexas.edu/' },
  { name: 'dictybase', url: 'http://dictybase.org/' },
  { name: 'ena', url: 'https://www.ebi.ac.uk/ena/browser/' },
  { name: 'ensembl', url: 'http://ensembl.org/' },
  { name: 'ensembl_fungi', url: 'https://fungi.ensembl.org/' },
  { name: 'ensembl_metazoa', url: 'https://metazoa.ensembl.org/' },
  { name: 'ensembl_plants', url: 'https://plants.ensembl.org/' },
  { name: 'ensembl_protists', url: 'https://protists.ensembl.org/' },
  { name: 'flybase', url: 'http://flybase.org/' },
  { name: 'gencode', url: 'http://gencodegenes.org/' },
  { name: 'genecards', url: 'https://www.genecards.org/' },
  { name: 'greengenes', url: 'http://greengenes.secondgenome.com/' },
  { name: 'gtrnadb', url: 'http://gtrnadb.ucsc.edu/' },
  { name: 'hgnc', url: 'http://www.genenames.org/' },
  { name: 'lncbase', url: 'http://www.microrna.gr/LncBase' },
  { name: 'lncbook', url: 'http://bigd.big.ac.cn/lncbook' },
  { name: 'lncrnadb', url: 'http://lncrnadb.org/' },
  { name: 'lncipedia', url: 'http://www.lncipedia.org/' },
  { name: 'malacards', url: 'https://www.malacards.org/' },
  { name: 'mgi', url: 'http://www.informatics.jax.org/' },
  { name: 'mirbase', url: 'http://www.mirbase.org/' },
  { name: 'mirgenedb', url: 'https://mirgenedb.org/' },
  { name: 'modomics', url: 'http://modomics.genesilico.pl/' },
  { name: 'noncode', url: 'http://www.noncode.org/' },
  { name: 'pdbe', url: 'http://www.ebi.ac.uk/pdbe/' },
  { name: 'pombase', url: 'http://www.pombase.org/' },
  { name: 'rdp', url: 'http://rdp.cme.msu.edu/' },
  { name: 'refseq', url: 'http://www.ncbi.nlm.nih.gov/refseq/' },
  { name: 'rfam', url: 'http://rfam.org/' },
  { name: 'rgd', url: 'http://rgd.mcw.edu/' },
  { name: 'sgd', url: 'http://yeastgenome.org/' },
  { name: 'silva', url: 'https://www.arb-silva.de/' },
  { name: 'snodb', url: 'http://scottgroup.med.usherbrooke.ca/snoDB/' },
  { name: 'snorna_database', url: 'http://lowelab.ucsc.edu/snoRNAdb/' },
  { name: 'snopy', url: 'http://snoopy.med.miyazaki-u.ac.jp/' },
  { name: 'srpdb', url: 'https://rth.dk/resources/rnp/SRPDB' },
  { name: 'tair', url: 'http://www.arabidopsis.org/' },
  { name: 'tarbase', url: 'http://www.microrna.gr/tarbase' },
  { name: 'tmrna_website', url: 'http://bioinformatics.sandia.gov/tmrna/' },
  { name: 'wormbase', url: 'http://www.wormbase.org/' },
  { name: 'zfin', url: 'https://zfin.org/' },
  { name: 'zwd', url: 'https://bitbucket.org/zashaw/zashaweinbergdata' },
];

export default info;